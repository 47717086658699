/* @import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

h1 {
    color: blue
}

/* styles.css */
:root {
    --global-font-family: 'Space Mono', monospace;
  }
  
  div, h1, h2, h3, h4, h5, h6 {
    font-family: var(--global-font-family);
  }
  
.column {
    float: left;
}

/* Left and right column */
.column.ingredients {
    width: 40%;
}

/* Middle column */
.column.methods {
    width: 60%;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {

    .column.ingredients,
    .column.methods {
        width: 100%;
    }
}

.content {
    max-width: 800px;
    margin: auto;
    background: white;
    padding: 10px;
}

.white-link {
    color: white;
    text-decoration: none;
}


.recipe-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
}

.recipe-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.black-link {
    color: black;
    text-decoration: none;
}

.black-link:hover {
    text-decoration: underline;
}

.white-link {
    color: white;
    text-decoration: none;
}

.white-link:hover {
    text-decoration: underline;
}

.current-step {
    font-weight: bold;
    opacity: 1;
}

.faded-step {
    font-weight: normal;
    opacity: 0.5;
}

.play-mode-message {
    margin-left: 1rem;
    font-size: 0.9rem;
    color: #555;
}

.faded-step:not(.current-step)::before {
    content: "";
    display: inline-block;
    width: 0;
    font-weight: bold;
    visibility: hidden;
}

.ingredients div,
.methods div {
  font-family: 'Roboto Mono', monospace;
}
